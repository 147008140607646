import Logo1 from  "./etihad.png"
import Logo2 from  "./alfutaim.png"
import Logo3 from  "./adnoc.png"
import Logo4 from  "./alfahim.png"
import Logo5 from  "./NPCC.png"
import Logo6 from  "./alnasser.png"
import Logo7 from  "./NFPC.png"
import Logo8 from  "./ados.png"
import Logo9 from  "./binhamoodah.png"
import Logo10 from  "./alittihadschool.png"
import Logo11 from  "./AIS.png"
import Logo12 from  "./almansoori.png"
import Logo13 from  "./alainfarms.png"
import Logo14 from "./ADCP.png"
import Logo15 from "./Decovision.png"
import Logo16 from "./EmiratesPrinting.png"
import Logo17 from "./elenco.png"
import Logo18 from "./NCF.png"
import Logo19 from "./IntlGoldenGroup.png"
import Logo20 from "./EMC.png"
import Logo21 from "./GSI.png"
import Logo22 from "./Weatherford.png"
import Logo23 from "./westernmotors.png"
import Logo24 from "./bloom.png"
import Logo25 from "./schlumberger.png"
import Logo26 from "./cicon.png"
import Logo27 from "./technip.png"
import Logo28 from "./alnasserprops.png"
import Logo29 from "./elencoemiratesgroup.png"
import Logo30 from "./ccc.png"
import Logo31 from "./FPI.png"
import Logo32 from "./agthia.png"
import Logo33 from "./TMI.png"
import Logo34 from "./GASOS.png"
import Logo35 from "./Gisco.png"
import Logo36 from "./ADPF.png"

export default[
    Logo1, Logo2, Logo3, 
    Logo4, Logo5, Logo6, 
    Logo7, Logo8, Logo9, 
    Logo10, Logo11, Logo12, 
    Logo13, Logo14, Logo15, 
    Logo16, Logo17, Logo18, 
    Logo19, Logo20, Logo21, 
    Logo22, Logo23, Logo24, 
    Logo25, Logo26, Logo27, 
    Logo28, Logo29, Logo30, 
    Logo31, Logo32, Logo33, 
    Logo34, Logo35, Logo36 
]